.login-dark {
    height:100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.778)), url('https://e0.pxfuel.com/wallpapers/171/601/desktop-wallpaper-casino-games-automotive-lighting-sky-beautiful-poker-luxury-black-card.jpg');
    background-size:cover;
    position:relative;
    background-color: #071724;
  }
  
  .login-dark form {
    width:90%;
    background-color:#1e2833;
    padding:30px;
    border-radius:4px;
    transform:translate(-50%, -50%);
    position:absolute;
    top:50%;
    left:50%;
    color:#fff;
    box-shadow:3px 3px 4px rgba(0,0,0,0.2);
  }
  
  .login-dark .illustration {
    text-align:center;
    padding:15px 0 20px;
    font-size:100px;
    color:#2980ef;
  }
  
  .login-dark form .form-control {
    background:none;
    border:none;
    border-bottom:1px solid #434a52;
    border-radius:0;
    box-shadow:none;
    outline:none;
    color:inherit;
  }
  
  .login-dark form .btn-primary {
    background:#214a80;
    border:none;
    border-radius:4px;
    padding:11px;
    box-shadow:none;
    text-shadow:none;
    outline:none;
  }
  
  .login-dark form .btn-primary:hover, .login-dark form .btn-primary:active {
    background:#214a80;
    outline:none;
  }
  
  .login-dark form .forgot {
    display:block;
    font-size:15px;
    color:#6f7a85;
    text-decoration:none;
    margin: 1rem 0 0.5rem;
  }
  
  .login-dark form .forgot:hover, .login-dark form .forgot:active {
    opacity:1;
    text-decoration:none;
  }

  .login-dark input::placeholder {
    color: #9d9d9d;
  }


  .signIn_SignUp {
    color:#6f7a85;
    text-decoration: none;
    font-size: 15px;
  }

  .signIn_SignUp span {
    color: #2f6cbd;
    font-size: 17px;
  }

  .google-auth {
    background-color: #fff;
    color: #172a3a;
    padding: 0.4rem 1rem;
    margin-top: 2rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    align-items: center;
  }

  .google-auth i {
    font-size: 2rem;
    color: red;
  }