.spinner-container {
    top: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000052;
}