.withdrawal__page__balance__section__bottom {
    font-weight: 600;
    font-size: 22px;
}

.withdrawal__page__balance__section__top {
    font-size: 13px;
}

.dus-ka-dum .passbook__details {
    color: #000;
}

.dus-ka-dum .passbook__detail__box {
    background: #eaeaff;
    border: 1px dashed #a6a1ff;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0;
}

.dus-ka-dum .to__bank {
    font-size: 13px;
    background: rgb(116, 116, 252);
    height: 25px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
    margin-top: 15px;
    color: #fff;
}

.dus-ka-dum .passbook__active__container {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    cursor: pointer;
}

.dus-ka-dum .passbook__detail {
    padding: 10px 5%;
}

.dus-ka-dum .passbook__detail__col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    margin-top: 15px;
}

.dus-ka-dum .passbook__active {
    height: 40px;
    width: 40px;
    background: rgb(2, 214, 133);
    border-radius: 0 0 0 50px;
    font-size: 21px;
    color: #fff;
    padding: 5px 12px;
}

.dus-ka-dum .changeCard {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
}

.dus-ka-dum .changeCard a {
    color: #333334;
}


.dus-ka-dum .withdrawal__amount__field {
    padding: 10px 0;
    margin-top: 20px;
}

.dus-ka-dum .withdrawal__field__header,
.dus-ka-dum .withdrawal__records__section__bottom__header {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}


.dus-ka-dum .withdrawal__input__field {
    height: 50px;
    width: 100%;
    background: #0dfcfc34;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
    color: #464545;
}

.dus-ka-dum .withdrawal__input__field__icon {
    flex-basis: 65px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.dus-ka-dum .withdrawal__amount__field .input {
    flex-basis: calc(100% - 65px);
    height: 100%;
    width: 100%;
    background: transparent;
    outline: 0;
    border: 0;
    border-radius: 3px;
    padding: 0 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 22px;
    color: #4d4d4e;
    display: flex;
    align-items: center;
}

.dus-ka-dum .withdrawal__input__notes {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    color: #000;
}

.dus-ka-dum .withdraw__btn {
    height: 40px;
    border-radius: 5px;
    color: #fff;
    border: 0;
    width: 100%;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 500;

    background: rgb(252, 148, 13);
}

.dus-ka-dum .withdrawal__records__section__record__top {
    height: 1px;
    width: 100%;
    background: #706e6e3a;
    margin-top: 20px;
}

.dus-ka-dum .withdrawal__records__section__bottom {
    padding: 15px 0;
}

.dus-ka-dum .withdrawalRecords__container__box {
    width: 100%;
    padding: 0px 4%;
    background: #f3f2ff;
    border: 1.2px solid #c7c4fd;
    border-radius: 5px;
    margin-top: 10px;
}

.dus-ka-dum .withdrawalRecords__container__box__top {
    width: 100%;
    border-bottom: 1px solid rgb(221, 218, 250);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
}

.dus-ka-dum .withdrawalRecords__container__box__top__top {
    font-size: 13px;
    color: #666;
}

.dus-ka-dum .withdrawalRecords__container__box__top__bottom {
    font-size: 14px;
    margin-top: 8px;
    color: #000;
}

.dus-ka-dum .withdrawalRecords__container__box__bottom {
    padding: 5px 0;
}

.dus-ka-dum .withdrawalRecords__container__box__bottom__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: sans-serif;
    font-size: 13px;
    color: #666;
}

.dus-ka-dum .passbook__details {
    color: #000;
}

.bank-card {
    margin-top: 2rem;
    background-color: #283546;
    padding: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1rem;
  }
  
  .bank-card p {
    color: #778d9c;
    font-weight: 500;
    font-style: italic;
    font-size: 1.2rem;
  }
  
  .bank-card div {
    width: 65px;
    aspect-ratio: 1/1;
    background-color: #0000002e;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    border-radius: 50%;
  
  }
  
  .bank-card i {
    font-size: 30px;
  }