@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,400;10..48,500;10..48,700;10..48,800&display=swap');

.probabilty-game-second-row {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  margin-top: 1rem;
}

.probabilty-game-second-row .numbers {
  display: flex;
  flex-direction: column;
}

.parity-record {
  margin-top: 1.5rem;
}

.parity-record p:first-child {
  color: #e1e0e0;
}

.parity-record-box {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 0.6rem;
}

.continuous-tab {
  overflow-x: auto !important;
  margin-top: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.scroll-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  overflow-x: auto;
  gap: 0.6rem;
  width: 100%;
  overflow-x: auto !important;
  white-space: nowrap;
}

.parity__records__circle {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
}

.parity__records__circle__no {
  width: 100%;
  aspect-ratio: 1 / 1;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 150;
}

.parity__records__circle__inner {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parity__records__circle__col {
  width: 100%;
  aspect-ratio: 1 / 2;
}

.parity__records__circle__col {
  width: 100%;
  aspect-ratio: 1 / 2;
}

/* .parity-myorder td {
  font-size: 13px;
} */


.parity-myorder td:first-child {
  text-align: left;
}

.parity-myorder td:last-child {
  text-align: right;
}

.parity-myorder-header td {
  font-weight: 500;
}

.parity-selected {
  display: flex;
  justify-content: center;
}

.parity-selected p {
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parity-result p {
  width: 38%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background-color: #e14738;
  color: #fff;
}

@media screen and (max-width: 378px) {
  .parity-record-box div {
    width: 40px;
    height: 40px;
  }
}

.parity-top {
  border: 1px solid rgba(0, 255, 255, 0.239);
  background: #1bb9c134;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dus-ka-dum .parity-top {
  background-color: #f5f5f5;
  border: none;
  border: 1px solid rgba(196, 4, 4, 0.396);
}

.dus-ka-dum .parity-top p:first-child {
  color: #777;
}

.dus-ka-dum .parity-top p:nth-child(2) {
  color: #000;
}

.parity-period p:first-child {
  color: #c8c5c5;
}

.dus-ka-dum .parity-period p:first-child {
  color: #000000;
  font-weight: 600;
}

.parity-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parity-count p:first-child {
  color: #ffffff;
  font-weight: 600;
}

.parity-count-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.parity-count-box p:first-child {
  background-color: rgba(255, 255, 255, 0.415);
  color: #fff;
  padding: 1rem;
  line-height: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.parity-count-box p:last-child {
  color: #ffffffe1;
  font-size: 14px;
}

.prity-colors {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
  z-index: 2;
}

.prity-colors div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.8rem;
}

.prity-colors div p:first-child {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}

.prity-colors div p:last-child {
  color: #efeded;
  letter-spacing: 1.5px;
  font-size: 1.1rem;
}

.paritynum-btns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
  margin-top: 1rem;
}

.paritynum-btns div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.dus-ka-dum .paritynum-btns {
  border-radius: 10px;
  border: 1px solid rgb(196, 4, 4);
}



.dus-ka-dum .paritynum-btns .item {
  background-color: #6F61C0;
}

.paritynum-btns p {
  font-size: 1.1rem;
  color: #fff;
}

.dus-ka-dum .paritynum-btns .item p {
  font-size: 1.5rem;
  color: #ff0206;
  font-weight: 800;
  text-align: center;
}

.parity-btn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2.5rem;
  gap: 1rem;
}

.parity-btn button {
  color: #fbab78;
  font-weight: 600;
  font-size: 15px;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
  background: none;
}

.parity-btn-active {
  background-color: #fbab78 !important;
  color: black !important;
}

.game-first-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.game-first-row div {
  text-align: right;
}

.game-first-row-box-out {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.game-first-row-box-out p {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 17px;
}

.game-first-row-box {
  height: 60%;
  width: 60%;
  border-radius: 5px;
}

.game-second-row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin-top: 1rem;
}

.game-second-row div {
  border: 1px solid #c8c5c5;
  text-align: center;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-second-row-color {
  padding: 1rem 0 !important;
}

.game-second-row-color p {
  background-color: #a2f4c4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #000;
  font-weight: 500;
}

.gameDetails-btn-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.gameDetails-btn-group button {
  background: none;
  padding: 0.8rem 0;
  border: none;
  border-radius: 8px;
  color: #f5f5f5;
  font-weight: 600;
  font-size: 16px;
}

.gameDetails-others {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.gameDetails-others p {
  color: #f1b81a;
  font-weight: 500;
}

.gameDetails-activeBtn {
  background: linear-gradient(to right bottom,
      rgb(255, 185, 9),
      rgb(254, 64, 45)) !important;
}

.game-coins {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.gold-coin {
  background-image: url('../../assets/images/gold.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.silver-coin {
  background-image: url('../../assets/images/silverCoin.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* color: midnightblue; */

}

.game-coins div p:first-child {
  font-weight: 600;
}

.input-container {
  background: #d3d3d33b;
}

.input-container input {
  background: none;
  border: none;
  color: #fff;
  font-weight: 600;
}

input:focus {
  outline: none;
}



.probability {
  transition: all 0.3s ease-in-out;
}

.start-box {
  /* padding: 1rem; */
  color: #fff;
  /* background-color: #38769858; */
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.start-box-content {
  background-color: #387798;
  height: 100%;
  padding: 1rem;
}

.start-box-outer {
  z-index: 9999;
}

.points-div {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.points-div h3 {
  font-size: 2rem;
  font-weight: 600;
}

.points-div button {
  border: none;
  padding: 0 1.5rem;
  border-radius: 2rem;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  background-color: #ffdcaa;
  height: 40px;
}

.contract-point {
  margin-top: 1rem;
}

.contract-point div {
  /* margin-top: 0.5rem; */
  display: flex;
  gap: 1.1rem;
  color: #000;
  font-size: 1.5rem;
}

.contract-point button {
  font-size: 1rem;
  font-weight: 500;
  border: none;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  background: none;
  color: #fff;
}

.contract-point-selected {
  background-color: #fcaa79 !important;
  color: black !important;
}


.single-entry {
  text-align: center;
  position: absolute;
  background-color: #387798;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  right: 3px;
  top: 3px;
  width: 140px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  z-index: 1;
  padding: 5px;
}

.single-entry p {
  font-weight: 500;
}

.single-entry p:nth-child(2) {
  margin-left: 2rem;
}

.single-entry p:nth-child(3) {
  margin-left: 4.8rem;
}




.power-x {
  border: 2px solid red;
}




.withdrawal__input__field {
  height: 50px;
  width: 100%;
  background: #f5f5f534;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
  color: #42353B;
}

.custon-ruppe {
  flex-basis: 65px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #42353B;
}

.slider {
  border: 1px solid rgb(121, 11, 11);
  padding: 0rem 0.5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.3rem;
}

.slide-item div {
  /* background-color: #6F61C0; */
  border-radius: 8px;
  color: #fff;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/images/chip.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 70%;
  margin: auto;
  font-size: 13px;
}

.slider-btn i {
  font-size: 25px !important;
  color: #6655d3;
}

.enter-btn {
  margin: auto;
}

.enter-btn {
  border: none;
  background-color: #83c837;
  border: 2px solid #d8523b;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  padding: 5px 30px;
  border-radius: 2rem;
  box-shadow: 0 0 15px #d8523b;
}

.dus-ka-dum-flag {
  position: absolute;
  bottom: -0.5rem;
  width: 80%;
  height: 30px;
  border-radius: none !important;
  color: #42353B;
  display: flex;
  justify-content: center;
  align-items: center;
}



.dus-ka-dum {
  font-family: 'Bricolage Grotesque', sans-serif;
}

.timer {
  display: grid;
  grid-template-columns:
    60% 38%;
  gap: 0.5rem;
}



.dkd .parity-period {
  background-color: #ffdd00;
}

.dkd .parity-count {
  background-color: #ffdd00;
}

.dkd-chip {
  background-image: url(/public/images/chip.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.game-name {
  background-color: #fff;
  padding: 0.8rem;
  color: #000;
  text-align: center;
  border-radius: 5px;
}

.dkd-table {
  margin-top: -15px;
}

.dkd-table .header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #ffc565;
  font-weight: 500;
  font-size: 13px;
}

.dkd-table .value {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  font-size: 13px;
}

.my-bit {
  font-size: 13px;
}

.countdown {
  position: fixed;
  top: 0;
}

.wrapper {
  display: grid;
  place-content: center;
  background-color: var(--background-color);
  min-height: 100vh;
  font-family: "Oswald", sans-serif;
  font-size: clamp(1.5rem, 1rem + 18vw, 15rem);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-color);
}

.wrapper > div {
  grid-area: 1/1/-1/-1;
}
.top {
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
}
