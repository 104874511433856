.modalContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBox {
    width: 275px;
    background-color: #3a4642;
    color: #fff;
    padding: 1rem ;
    border-radius: 10px;
}

.modalBox h2 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

.modalBox p {
    font-size: 0.9rem;
    color: #e2e2e2;
}


.modalBtn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    align-items: center;
}

.modalBtn button {
    color: #fff;
    background: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
}

.modalBtn button:last-child {
    background-color: #ffffff;
    color: black;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
}

@media screen and (max-width: 800px) {
    .modalBtn button {
        cursor: default;
    }
}
