.welcome-custom {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.welcome-custom img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.play-now {
    color: black;
    text-transform: uppercase;
    font-weight: 900;
    text-decoration: none;
    background-color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    position: relative;
}